.statement-heading{
    text-transform: uppercase;
    font-size: 2rem;
    color: #3A405A;
}
.statement-contents{
    color: #3A405A;
    font-family: Roboto;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.2rem;
    text-transform: capitalize;
}

    
