
.lifetime-heading{
    
    color: #3a405a;
    font-size: 2.1875rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}
.lifetime-subheading{
    font-family: Roboto;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.875rem;
    
    margin: 0;
    padding: 0;
}
.lifetime-btn{
    text-align: center;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    font-size: 1.2rem;
    
}