.thank-you-popup {
    
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    font-family: sans-serif;
  }
  
  .thank-you-title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .thank-you-body {
    text-align: center;
    padding: 20px;
  }
  
  .thank-you-message {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 15px;
  }
  
  .receipt-link {
    margin-bottom: 0;
  }
  
  .receipt-link-button {
   
    text-decoration: none;
  }

  .certificate-link {
    margin-bottom: 0;
  }
  
  .certificate-link-button {
    color: green; 
    text-decoration: none;
  }
  
  .popup-close {
    position: absolute;
    top: 15px;
    right: 15px;
    color: #333;
    font-size: 20px;
    background: transparent;
    border: none;
  }
  
  
  @media (max-width: 768px) {
    .thank-you-body {
      padding: 15px;
    }
  }
  