.about-section{
    margin: 0rem 0.75rem;
}
.about-heading{
color: #3A405A;
font-family: Roboto;
font-size: 2.1875rem;
font-style: normal;
font-weight: 700;
line-height: normal;
text-transform: uppercase;
}
.about-img{
    border-radius: 25px;
    padding: 0;
    margin-bottom: 1rem;
    
}
.about-text{
    color: #3A405A;
font-family: Roboto;
font-size: 1.1rem;
font-style: normal;
font-weight: 400;
line-height: 2.2rem; 
text-transform: capitalize;
}
.about-donate-btn{
    text-align: center;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    font-size: 1.2rem;
}