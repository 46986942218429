.donation-type {
  text-align: center;
  border-radius: 30px;
  height: 27rem;
}

.donation-image {
  padding: 1rem;
  width: 8rem;
  height: 8rem;
}
.donation-img-container{
  background-color: #fff;

width: 9rem;
height: 9rem;
margin-left: auto;
margin-right: auto;
border-radius: 50%;
margin-top: 8%;

}
.donation-title {
  margin-top: 10px;
  font-size: 1.5rem;
}

.donation-description {
  margin-top: 10px;
  color: white;
}

.btn-donate {
  padding: 5% 8%;
  background-color: #fff;
  color: #000;
  border: none;
  cursor: pointer;
  border-radius: 10px;
}

.card-title{
  color: white;
}

@media screen and (max-width:480px) {
  .donation-description{
    font-size: 0.75rem;
  }
  .donation-type{
    margin: -0.5rem;
  }
  .donation-type{
    height: 27rem;
  }
}
