.dash-top-btns{
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    left: 1.5rem;
}
.logout-btn{
    position: relative;
    right: 6rem;
}
.add-prod-btn{
    position: relative;
    left: 17rem;
}
.download-btn{
    position: relative;
    left: 32rem;

}

@media screen and (max-width:480px) {
    .logout-btn{
       margin-left:5.2rem ;
        
    }
    .dash-top-btns{
        display: flex;
        flex-direction: row;
        justify-content: center;
        position: relative;
        left: 2.5rem;
    }
    .add-prod-btn{
        position: relative;
        left: 0;
    }
    .download-btn{
        position: relative;
        left: 0;
    }
    .don-list-th{
        
        display: flex !important; 
        flex-direction: column !important;
        align-items: center !important;

    }
    .prod-table{
        font-size: 0.5rem;
    }
    .table-edit-btn{
        font-size: 0.5rem;
    }
    .table-del-btn{
        font-size: 0.5rem;
    }
    .donator-lst-table{
        font-size: 0.4rem;
    }
    .dash-top-btns button{
        font-size: 0.5rem;
    }

   
    
}