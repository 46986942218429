.hero-img-container {
    position: relative;
    width: 100%;
  }
  
  .hero-img {
    width: 100%;
    height: 50%;
    display: block;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
  }
  
  .heroimg-text {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 35px;
    transform: translate(-50%, -50%);
    color: #fff;
    text-align: center;
    z-index: 1;
  }
  
  .heroimg-text h5 {
    font-size: 5rem;
    margin: 0;
  }
  
  