
.input-group input {
  border-radius: 10px !important;
  height: 3rem;
}

.input-group button {
  border-radius: 10px;
  height: 3rem;
  font-size: 1.5rem;
  
}

.newsletter-input-sec {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.newsletter-heading {
  color: #3a405a;
  font-family: Roboto;
  font-size: 2.7rem;
  font-style: normal;
  font-weight: 700;
  line-height: 4.75rem;
  text-transform: uppercase;
}

.newsletter-img {
  width: 70%;
}

.newsletter-subheading {
  font-family: Roboto;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.875rem;
  text-transform: capitalize;
}

.newsletter-email {
  border-radius: 10px;
}

.newsletter-sub-btn {
  border-radius: 10px;
}
.newsletter-right-sec{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

@media screen and (max-width: 480px) {
  .newsletter-section {
    text-align: center;
  }

  .newsletter-heading {
    color: #3a405a;
    font-family: Roboto;
    font-size: 1.51rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.5rem;
    text-transform: uppercase;
  }
  .newsletter-subheading {
    font-family: Roboto;
    font-size: 1.15rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    text-transform: capitalize;
  }
  .newsletter-img {
    width: 100%;
  }
}