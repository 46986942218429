.donation-product {
  background-color: #d9d9d9;
  border-radius: 15px;
  padding: 0.5rem;
  margin: 1% 3%;
  text-align: center;
}
.product-name{
    margin-bottom: 0;
    font-size: 1.5rem;
}
.product-description{
    margin-bottom: 0;
    font-size: 1.5rem;
}
.quantity-section{
    align-items: center;
    justify-content: center;
}
.product-amount{
    font-size: 1.5rem;
    margin-bottom: 0;
}
.quantity-btn{
    width: 3.1rem;
    height: 2.4rem;
}
.product-quantity{
    font-size: 1.2rem;
}
.donate-btn-sec{
    margin-top: auto;
    margin-bottom: auto;
}
.donate-btn-sec button{
    border-radius: 12px;
}
















@media screen and (max-width: 480px) {
  .product-name {
    font-size: 1.5625rem;
  }
  .product-description {
    font-size: 1.125rem;
    margin: 0;
  }
  .product-amount {
    margin-bottom: 0;
    margin-right: 0.5rem;
    font-size: 1.121rem;
  }
  .product-names {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .quantity-btn {
    background-color: #fff;
    width: 2rem;
    height: 2rem;
    font-size: .9rem;
  }
  .quantity-btn p{
    position: relative;
    bottom: 0.2rem;
  }

  .quantity-btn p{
    margin-bottom: 1rem;
  }
  .quantity-and-amount{
    margin-bottom: 0.5rem;
    justify-content: center;
  }
  .donate-button{
    width: 8rem;
    border-radius: 15px !important;
  }
  .product-mob-right{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

}
