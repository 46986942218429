.comm-header-logo{
  width: 7vw;
}
  
  .header-item {
    color: #3A405A;
    font-family: Roboto;
    font-size: 1.5rem; 
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 1rem;
    margin-right: 1rem;
  }
.langselectorHead button{
  color: rgba(0,0,0,.5);
  color: #3A405A;
  font-family: Roboto;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 1rem;
  margin-right: 1rem;
  border: none;
}
  
  @media (max-width: 768px) {
    .logo {
      font-size: 1.4rem; 
    }
    
    .header-item {
      font-size: 0.9rem; 
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
  }
  .header-btn-donate{
    padding: 0.5rem 1rem;
    border-radius: 10px;
    width: 150%;
    font-size: 1.2rem;
    
      }
  

@media (max-width: 768px) {
  .header-btn-donate{
    
    
    width: 100%;
    font-size: 1.2rem;
    
      }
  .comm-header-logo{
    width: 15vw;
  }
  .navbar {
    min-width: 340px;
  }

  .navbar-toggler-icon {
    font-size: 1rem;
  }

  .navbar-toggler.collapsed {
    font-size: 24px;
  }

  .navbar-toggler-icon:focus,
  .navbar-toggler-icon:active {
    outline: none; 
  }

  .navbar-nav .nav-link {
    font-size: 18px; 
  }
  .header-donate-button{
    margin: 0;
  }
  .langselectorHead button{
    
    font-size: 18px;
    padding: 0;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    margin-right: 0;
   
 
    
   
  }
}
