
.footer-links{
  
  text-decoration: none;
  color: #000;
}
.footer-links :hover{
  text-decoration: underline;
  color: #28a745;
}

.footer-section {
    background-color: #f8f9fa;
    padding: 20px 0;
   
    border-radius: 10px;
    margin-bottom: 1rem;
  }
  
  .vertical-line {
    border: 1px solid #dee2e6;
    
  }
  

  .fab {
    margin: 0 10px;
  }
  .footer-map{
    
    border-radius: 15px;
    width: 65vw;
  }
.footer-map-div iframe{
border: none;
}
.member-card{
  margin-bottom: 5rem;
}


  @media screen and (max-width:480px) {
    .footer-items-row{
      text-align: center;
    }
    .footer-map-div{
      text-align: center;
    }
    .footer-map{
      width: 90vw !important;
      height: 35vh;
      border: none;
    }
  }