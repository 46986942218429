
  
  .popup-content {
    background: #fff;
    padding: 20px;
    max-width: 400px;
    width: 100%;
    position: relative;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  
  .popup-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 18px;
    color: #333;
  }
  
  h2 {
    text-align: center;
    color: #333;
  }
  
  .popup-form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    color: #333;
  }
  
  input,
  textarea {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .popup-btn {
    background-color: #4caf50;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    display: block;
    margin: auto;
    margin-top: 15px;
  }
  
  .popup-btn:hover {
    background-color: #45a049;
  }
   
   .popup-container {
    position: relative;
  }
  
  .popup-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 300px;
  }
  
  .popup-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
  }
  
  .popup-form-group {
    margin-bottom: 15px;
  }
  
  .popup-form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .popup-form-group input,
  .popup-form-group textarea {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .popup-btn {
    cursor: pointer;
  }
  
  .donate-button {
    background-color: #4caf50;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
  }
  
  .donate-button:hover {
    background-color: #45a049;
  }
  .error-message {
    color: red;
  }
     