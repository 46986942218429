
.glance-tile {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .icon-square {
    color: #3A405A;
    width: 7rem; 
    height: 7rem; 
    border-radius: 10px;
    overflow: hidden;
    margin: 0 auto 10px;
    box-shadow: 0px 0px 21px #00000029;
    padding: 1rem;
  }
  .icon-square:hover{
    background-color: #3A405A;
    transition: background-color 0.25s, filter 0.25s ease-in-out;
  }

  .icon-square:hover .icon {
    filter: brightness(0) saturate(100%) invert(100%);
    transition: background-color 0.25s, filter 0.25s ease-in-out;
  }
  
  .icon {
    width: 100%;
    height: 100%;
    padding: 0.5rem;
  }
  .title{
    color: #3A405A;
font-size: 90%;
font-style: normal;
font-weight: 700;
line-height: normal;
text-transform: uppercase;
  }
