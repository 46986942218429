.donation-heading {
  margin-top: 8%;
  color: #3a405a;
  text-align: center;
  font-family: Roboto;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.glance-heading{
  color: #3A405A;
font-family: Roboto;
font-size: 2.1875rem;
font-style: normal;
font-weight: 700;
line-height: normal;
text-transform: uppercase;
}

.donate-tile-row {
  padding: 0vh 2vw;
}
.donation-type-1{
  background-color: black;
}
.donation2-m{
  display: none;
}
@media screen and (max-width:480px){
  .donation2{
    display: none;
  }
  .donation2-m{
    display: block;
  }
  .donation-section-div{
    margin: 0rem 0.5rem;
  }
  .glance-sec{
    margin: 0rem 1rem;
  }
}


