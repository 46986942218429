.map-iframe{
    border-radius: 10px;
    width: 100%;
    height: 30rem;
}
.contact-card{
    margin-top: 70.5px;
    background: #fff;
    box-shadow: 0px 3px 6px rgba(0 0 0/16%);
    padding: 3.5rem 1.5rem;
    text-align: center;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
    border-radius: 10px;
}
.contact-card:hover {
    transform: translateY(-15px);
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
    box-shadow: 0px 15px 25px rgb(1 106 62 25%);
}
.contact-logo{
    margin-bottom: 1rem;
    width: 100px;
    height: 100px;
}
.contact-link-desc{
    font-size: 1rem;
    color: black;
    text-decoration: none;
}